import React, { createRef } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { Site } from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/Home";
import SelectTeam from "./routes/SelectTeam";
import Datadog from "react-datadog";
import { datadogRum } from "@datadog/browser-rum";

// datadogRum.init({
//   applicationId: "2f845b4e-d038-4e6b-93b3-c969c219610a",
//   clientToken: "pub4cfef63e92e53ac8aa20007b4df86405",
//   site: "datadoghq.com",
//   service: "aifa-react-prototype",
//   env: "dev",
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sampleRate: 100,
//   sessionReplaySampleRate: 20,
//   trackInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: "mask-user-input",
// });

// datadogRum.startSessionReplayRecording();

export const ROUTES = [
  { path: "/", name: "Home", element: <Home />, nodeRef: createRef() },
  {
    path: "/select-team",
    name: "About",
    element: <SelectTeam />,
    nodeRef: createRef(),
  },
];
const router = createBrowserRouter([
  {
    path: "/",
    element: <Site />,
    children: ROUTES.map((route) => ({
      index: route.path === "/",
      path: route.path === "/" ? undefined : route.path,
      element: route.element,
    })),
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Datadog
    applicationId="2f845b4e-d038-4e6b-93b3-c969c219610a"
    clientToken="pub4cfef63e92e53ac8aa20007b4df86405"
    service="aifa-react-prototype"
    sessionReplayRecording
  >
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Datadog>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
